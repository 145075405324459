
import Vue from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import {
  V1EntitiesReviewsAudience,
  V1EntitiesReviewsCouple,
  V1EntitiesReviewsExpertList,
  V1EntitiesReviewsIndexReview,
  V1EntitiesReviewsShowReview,
  PostV1Invitees,
} from '@/services/api/tsxass';
import PageTitle from '@/components/common/PageTitle.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import ReviewSingleExpertGroupList from '@/components/assessmentChecklist/ReviewExperts/ReviewSingleExpertGroupList.vue';
import SidebarReviewExperts from '@/components/assessmentChecklist/ReviewExperts/SidebarReviewExperts.vue';

enum ReviewStatus {
  REVIEW = 'review',
  APPROVED = 'approved',
}

export default Vue.extend({
  name: 'AssessmentReviewExpertList',

  components: {
    PageTitle,
    UserAvatar,
    ReviewSingleExpertGroupList,
    SidebarReviewExperts,
  },

  inject: ['RouteNames'],

  data() {
    return {
      loading: false,
      reviews: [] as V1EntitiesReviewsIndexReview[],
      review: null as V1EntitiesReviewsShowReview | null,
      couples: [] as V1EntitiesReviewsCouple[],
      expertLists: [] as V1EntitiesReviewsExpertList[],
      UserCardColumnNames,
    };
  },

  computed: {
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    surveyeeId(): string {
      return this.$route.params.surveyeeId;
    },
    isApprovedStatus() {
      return this.review?.status === ReviewStatus.APPROVED;
    },
    userData(): V1EntitiesReviewsAudience | null {
      return this.review?.surveyee ?? null;
    },
    couplesData() {
      return {
        groups: this.expertLists,
        couples: this.couples,
      };
    },
  },

  watch: {
    surveyeeId() {
      this.loadReviewsForSurveyee();
    },
  },

  mounted() {
    this.loadReviewsForSurveyee();
  },

  methods: {
    async loadReviews() {
      try {
        this.loading = true;
        const {
          data: { reviews },
        } = await tsxassApi.getV1ReviewsSurveyId(this.surveyId);
        this.reviews = reviews;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async loadReviewsForSurveyee() {
      try {
        this.loading = true;
        const {
          data: { couples, expertLists, review },
        } = await tsxassApi.getV1ReviewsSurveyIdSurveyeeId(this.surveyId, this.surveyeeId);
        this.review = review;
        this.couples = couples;
        this.expertLists = expertLists;
        await this.loadReviews();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async approveAll() {
      try {
        this.loading = true;
        await tsxassApi.postV1ReviewsSurveyIdApproveSurveyeeId(this.surveyId, this.surveyeeId);
        await this.loadReviewsForSurveyee();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async addUsersToGroup({ selectedGroupId, userIds }) {
      if (!userIds.length) {
        return;
      }
      try {
        this.loading = true;
        await tsxassApi.postV1CouplesByBatch({
          expertListId: selectedGroupId,
          couples: userIds.map((userId: string) => ({
            expertUserId: userId,
            surveyeeUserId: this.surveyeeId,
          })),
        });
        await this.loadReviewsForSurveyee();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async addExternalExperts(externalUsers: PostV1Invitees[]) {
      if (!externalUsers.length) {
        return;
      }
      try {
        this.loading = true;
        await Promise.allSettled(
          externalUsers.map(async (user) => {
            await tsxassApi.postV1Invitees(user);
          }),
        );
        await this.loadReviewsForSurveyee();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async deleteExpert({ expertId, expertListId }: { expertId: string, expertListId: number }) {
      try {
        this.loading = true;
        await tsxassApi.deleteV1ReviewsSurveyIdSurveyeeId(this.surveyId, this.surveyeeId, expertId, expertListId);
        await this.loadReviewsForSurveyee();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
