
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';
import { V1EntitiesReviewsIndexReview } from '@/services/api/tsxass';
import UserAvatar from '@/components/common/UserAvatar.vue';

export default Vue.extend({
  name: 'SidebarReviewExperts',
  components: {
    UserAvatar,
  },

  props: {
    reviews: {
      type: Array as PropType<V1EntitiesReviewsIndexReview[]>,
      required: true,
    },
    activeReviewId: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
  },

  inject: ['RouteNames'],

  data() {
    return {
      hoverableReview: null as V1EntitiesReviewsIndexReview | null,
    };
  },

  computed: {
    // id ревью, у которых не нужно выводить разделитель:
    // активная карточка и предыдущая, карточка под курсором и предыдущая, последняя карточка
    reviewsWithoutDivider() {
      if (!this.reviews.length) {
        return [];
      }

      const lastReviewId = this.reviews[this.reviews.length - 1]?.surveyee?.userId;

      const res = [this.activeReviewId, lastReviewId];

      const coupleBeforeActiveIndex = this.reviews.findIndex(
        (review) => review.surveyee.userId === this.activeReviewId,
      ) - 1;
      const coupleBeforeHoverableIndex = this.reviews.findIndex(
        (review) => review.surveyee.userId === this.hoverableReview?.surveyee.userId,
      ) - 1;

      if (coupleBeforeActiveIndex >= 0) {
        res.push(this.reviews[coupleBeforeActiveIndex].surveyee.userId);
      }

      if (coupleBeforeHoverableIndex >= 0) {
        res.push(this.reviews[coupleBeforeHoverableIndex].surveyee.userId);
        res.push(this.reviews[coupleBeforeHoverableIndex + 1].surveyee.userId);
      }

      return res;
    },
  },

  methods: {
    reviewRoute(userId: string): Location | String {
      return {
        name: this.RouteNames.R_APP_ASSESSMENT_REVIEW_EXPERT,
        params: { surveyeeId: userId },
      };
    },
  },
});
